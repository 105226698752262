<template>
<div class="my-4">
  <b-container>
    <b-row class="text-center mb-4" align-h="center" v-if="loadingRisks">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loadingRisks">
      <b-col>
        <b-card class="mb-3">
          <b-button class="mr-2" variant="outline-primary" v-on:click="getXls">download</b-button>
          <b-button v-if="permissions.addRisk" variant="outline-primary" :to="{ name: 'RiskAdd' }">add risk</b-button>
          <b-form-group horizontal class="mt-4">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Type to Search" />
                <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
            </b-input-group>
          </b-form-group>
        </b-card>
        <b-card class="mb-3" v-for="risk in showRisks" :key="risk.id">
          <risk
            :risk="risk"
            :username="user.username"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import zipcelx from 'zipcelx'

import ac from '../libs/accesscontrol'

import Risk from '@/components/Risk.vue'

export default {
  name: 'Risks',
  components: {
    Risk
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'risks', action: 'open risks' })
    this.permissions.addRisk = ac.can(this.user.acgroups).createAny('marrisk').granted
    this.loadRisks()
  },
  data () {
    return {
      permissions: {
        addRisk: false
      },
      buttonvariant: 'primary',
      event: {},
      filter: '',
      loadingRisks: true,
      risks: '',
      showRisks: ''
    }
  },
  methods: {
    getXls: function () {
      this.$stat.log({ page: 'risks', action: 'download risks' })
      let data = []
      const headings = [
        { value: 'RiskId', type: 'string' },
        { value: 'Name', type: 'string' },
        { value: 'Definition', type: 'string' }
      ]
      data.push(headings)
      for (let i = 0, len = this.risks.length; i < len; i++) {
        let row = [
          { value: this.risks[i].id, type: 'string' },
          { value: this.risks[i].name, type: 'string' },
          { value: this.risks[i].comments, type: 'string' }
        ]
        data.push(row)
      }
      const config = { filename: 'mar-risks', sheet: { data: data } }
      zipcelx(config)
    },
    loadRisks: async function () {
      this.$logger.debug('loadTopics started')
      this.loadingRisks = true
      try {
        let apiName = 'cosmos'
        let path = '/risks'
        let response = await this.$Amplify.API.get(apiName, path)
        this.risks = _.sortBy(response, 'name')
        this.showRisks = this.risks
        this.loadingRisks = false
        this.$logger.debug(this.risk)
      } catch (e) {
        this.$logger.wrn('saved ERROR: ', e)
      }
    },
    search: function () {
      if (this.filter.length > 2) {
        this.showRisks = this.risks.filter(item => item.name.toUpperCase().includes(this.filter.toUpperCase()))
      } else {
        this.showRisks = this.risks
      }
    }
  },
  watch: {
    'filter': 'search'
  }
}
</script>

<style>
</style>
